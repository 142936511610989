// import React, { useEffect, useState } from "react";
// import {
//   AvailableFareConnectionModel,
//   AvailableFareLegModel,
//   AvailableFareModel,
// } from "WebApiClient";
// import FareLegConnectionTable from "./Fare.Leg.ConTbl";
// import FareLegHeader from "./Fare.Leg.Header";
// import _ from "lodash";

// interface LegProps {
//   Data: any;
//   ShowFlightTimes: boolean;
//   SelectedConnection: number;
//   GetSelectedConnectionIndexByLegIndex?: any;
//   LegIndex: number;
//   SelectConnection: (legIndex: number, connectionIndex: number) => void;
//   ShowLegPlatingCarrier: boolean;
//   ApplicableConnections?: number[];
//   Fare: any;
//   ToggleFlightTimes: () => void;
//   isActivate?: boolean;
//   setWL?: () => void;
// }

// const FareLeg: React.FC<LegProps> = (props) => {
//   const con: AvailableFareConnectionModel = props.Data.connections![props.SelectedConnection];

//   useEffect(() => {
//     if (props.isActivate) {
//       sortByTime(props.Data.connections);
//     }
//   }, []);

//   function sortByTime(connection: any) {
//     let arr: any = [];
//     let con: any = _.map(connection, "connectionHeader");
//     _.map(con, (itr: any, index: number) => {
//       var days = itr.legTravelTime.daysStayOver * 1440;
//       let hours: any = itr.legTravelTime.hours * 60;
//       let totalMinutes = days + hours + itr.legTravelTime.minutes;
//       let obj = {
//         index: index,
//         totalMinutes: totalMinutes,
//         itr: itr,
//       };
//       arr.push(obj);
//     });
//     let minValue: any = _.minBy(arr, "totalMinutes");
//     props.SelectConnection(props.LegIndex, minValue.index);
//   }

//    props.Data.connections.sort((a: any, b: any) => {
//     const legTravelTimeA = a.connectionHeader
//       ? a.connectionHeader.legTravelTime.hours * 60 +
//         a.connectionHeader.legTravelTime.minutes
//       : Infinity;
//     const legTravelTimeB = b.connectionHeader
//       ? b.connectionHeader.legTravelTime.hours * 60 +
//         b.connectionHeader.legTravelTime.minutes
//       : Infinity;
//     return legTravelTimeA - legTravelTimeB;
//   });

//   return (
//     <React.Fragment>
//       <FareLegHeader
//         key={"fareHeader" + props.LegIndex}
//         Data={con?.connectionHeader!}
//         LegIndex={props.LegIndex}
//         PlatingCarrierCode={props.Data.platingCarrier!.code!}
//         PlatingCarrierName={props.Data.platingCarrier!.hint!}
//         ShowLegPlatingCarrier={props.ShowLegPlatingCarrier}
//         ToggleFlightTimes={props.ToggleFlightTimes}
//         IsExpanded={props.ShowFlightTimes}
//       />
//       {props.ShowFlightTimes && (
//         <FareLegConnectionTable
//           key={"FareLegConnectionTable" + props.LegIndex}
//           Data={props.Data.connections!}
//           // Data={sortedConnections[props.SelectedConnection]?.connectionHeader}
//           SelectedConnection={props.SelectedConnection}
//           GetSelectedConnectionIndexByLegIndex={
//             props.GetSelectedConnectionIndexByLegIndex
//           }
//           LegIndex={props.LegIndex}
//           SelectConnection={props.SelectConnection}
//           IsWebFare={props.Data.isWeb}
//           ApplicableConnections={props.ApplicableConnections}
//           Fare={props.Fare}
//         />
//       )}
//     </React.Fragment>
//   );
// };

// export default FareLeg;

import React, { useEffect, useState } from "react";
import {
  AvailableFareConnectionModel,
  AvailableFareLegModel,
  AvailableFareModel,
} from "WebApiClient";
import FareLegConnectionTable from "./Fare.Leg.ConTbl";
import FareLegHeader from "./Fare.Leg.Header";
import _ from "lodash";

interface LegProps {
  Data: any;
  ShowFlightTimes: boolean;
  SelectedConnection: number;
  GetSelectedConnectionIndexByLegIndex?: any;
  LegIndex: number;
  SelectConnection: (legIndex: number, connectionIndex: number) => void;
  ShowLegPlatingCarrier: boolean;
  ApplicableConnections?: number[];
  Fare: any;
  ToggleFlightTimes: () => void;
  isActivate?: boolean;
  setStatus: (status: boolean) => void;
  headerOffVisible:boolean;
  upscaleLength:number
}

const FareLeg: React.FC<LegProps> = (props) => {
  //   const con: AvailableFareConnectionModel =
  //     props.Data.connections![props.SelectedConnection];

  const con: AvailableFareConnectionModel = _.find(props.Data.connections, {
    index: props.SelectedConnection,
  });

  useEffect(() => {
    if (props.isActivate && props.upscaleLength == 1000) {
      sortByTime(props.Data.connections);
    }
  }, []);
  

  function sortByTime(connections: any) {
    let arr: any = [];
    _.forEach(connections, (conn, index) => {
      const leg = conn.connectionHeader.legTravelTime;
      const totalMinutes =
        leg.daysStayOver * 1440 + leg.hours * 60 + leg.minutes;
      arr.push({
        index: conn.index,
        totalMinutes: totalMinutes,
        connection: conn,
      });
    });
    const minValue: any = _.minBy(arr, "totalMinutes");
    props.SelectConnection(props.LegIndex, minValue.index);
    props.setStatus(true);
  }

  return (
    <React.Fragment>
      {
        props.headerOffVisible && <FareLegHeader
        key={"fareHeader" + props.LegIndex}
        Data={con?.connectionHeader!}
        LegIndex={props.LegIndex}
        PlatingCarrierCode={props.Data.platingCarrier!.code!}
        PlatingCarrierName={props.Data.platingCarrier!.hint!}
        ShowLegPlatingCarrier={props.ShowLegPlatingCarrier}
        ToggleFlightTimes={props.ToggleFlightTimes}
        IsExpanded={props.ShowFlightTimes}
      />
      }
      
      {props.ShowFlightTimes &&  (
        <FareLegConnectionTable
          key={"FareLegConnectionTable" + props.LegIndex}
          Data={props.Data.connections!}
          SelectedConnection={props.SelectedConnection}
          GetSelectedConnectionIndexByLegIndex={
            props.GetSelectedConnectionIndexByLegIndex
          }
          LegIndex={props.LegIndex}
          SelectConnection={props.SelectConnection}
          IsWebFare={props.Data.isWeb}
          ApplicableConnections={props.ApplicableConnections}
          Fare={props.Fare}
          setStatus={props.setStatus}
          headerOffVisible={props.headerOffVisible}
          upscaleLength={props.upscaleLength}
        />
      )}
    </React.Fragment>
  );
};

export default FareLeg;
