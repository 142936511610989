import { faMedium } from "@fortawesome/free-brands-svg-icons";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { faChalkboardTeacher, faCircle, faCoffee, faCookieBite, faCouch, faExchange, faFastForward, faGavel, faGlassMartini, faInfoCircle, faMapMarkerQuestion, faMapSigns, faMoneyCheckEditAlt, faPaw, faPlug, faRandom, faRedo, faShoppingBag, faSuitcase, faTachometerFastest, faTicketAlt, faTv, faUndoAlt, faUserCheck, faUtensils, faWifi, IconDefinition, faCar } from "@fortawesome/pro-solid-svg-icons";
import { IncludedServiceMainType, IncludedServiceSubType } from "WebApiClient";
import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { FareHeaderView } from 'WebApiClient';
import IncServices from "components/Shared/IncServices"
import Tooltip from '@mui/material/Tooltip';
interface IncludedServicesProps {
    readonly Data: FareHeaderView;
    readonly FareIndex: number;
}

const FareHeaderIncludedServices: React.FC<IncludedServicesProps> = (props) => {

    // Extract unique hints
    const combinedHints = Array.from(
        new Map(
            props?.Data?.includedServices!
                .flatMap((item) => item.services!.map((service) => ({ hint: service.hint })))
                .map((obj) => [obj.hint, obj]) // Use the hint as the key
        ).values()
    );

    return (
        <React.Fragment>
            <div className="ancillaries align-bottom">
                <FreeBaggeIndicator HasFreeBaggage={props.Data.fareHasFreeBaggage} />
                {
                    props.Data.includedServices!.length > 0 &&
                    <span> | </span>
                }
                    <Tooltip title={
                        <React.Fragment>
                    {
                        combinedHints.length &&  combinedHints!.map((sub: any, subIndex: any) => <span key={"mainSer_" + subIndex}>
                            <li>
                                {sub.hint}
                            </li>
                        </span>
                        )}
                    </React.Fragment>
                }>
                <span>
                    {props.Data.includedServices!.map((mainGroup, mainIndex) =>

                        <IncServices key={"IncServices"+mainIndex} data={mainGroup} combinedHints={combinedHints}/>
                    )}
                </span>
                </Tooltip>
            </div>
        </React.Fragment>
    );

    function GetIcon(data: any): any {
        let iconClass: any = faInfoCircle;
        switch (data.subType) {
            case IncludedServiceSubType.FreeBaggageAllowance:
                iconClass = faSuitcase;
                break;
            case IncludedServiceSubType.CarryOnBaggage:
                iconClass = faShoppingBag;
                break;
            case IncludedServiceSubType.Lunch:
                iconClass = faUtensils;
                break;
            case IncludedServiceSubType.Snack:
                iconClass = faCookieBite;
                break;
            case IncludedServiceSubType.SnacksAndBeverage:
                iconClass = faUtensils;
                break;
            case IncludedServiceSubType.NonAlcoholicDrink:
                iconClass = faCoffee;
                break;
            case IncludedServiceSubType.FreeAlcoholicDrink:
                iconClass = faGlassMartini;
                break;
            // INFLIGHT ENTERTAINMENT
            case IncludedServiceSubType.PersonalEntertainment:
                iconClass = faTv;
                break;
            case IncludedServiceSubType.InternetAccess:
                iconClass = faWifi;
                break;
            case IncludedServiceSubType.InSeatpower:
                iconClass = faPlug;
                break;
            case IncludedServiceSubType.Pet:
                iconClass = faPaw;
                break;
            case IncludedServiceSubType.Security:
                iconClass = faGavel;
                break;
            case IncludedServiceSubType.PriorityBoarding:
                iconClass = faFastForward;
                break;
            case IncludedServiceSubType.Ticket:
                iconClass = faTicketAlt;
                break;
            case IncludedServiceSubType.CheckIn:
                iconClass = faChalkboardTeacher;
                break;
            case IncludedServiceSubType.ReBooking:
                iconClass = faRedo;
                break;
            case IncludedServiceSubType.RefundAllowed:
                iconClass = faMoneyCheckEditAlt;
                break;
            case IncludedServiceSubType.PriorityCheckIn:
                iconClass = faUserCheck;
                break;
            case IncludedServiceSubType.FastTrack:
                iconClass = faTachometerFastest;
                break;
            case IncludedServiceSubType.ChangeAfterDeparture:
                iconClass = faUndoAlt;
                break;
            case IncludedServiceSubType.ChangeBeforeDeparture:
                iconClass = faUndoAlt;
                break;
            case IncludedServiceSubType.ChangeInCaseOfNoShow:
                iconClass = faMapMarkerQuestion;
                break;
            case IncludedServiceSubType.OpenReturnDate:
                iconClass = faExchange;
                break;
            case IncludedServiceSubType.Stopover:
                iconClass = faMapSigns;
                break;
            default:
                switch (data.type) {
                    case IncludedServiceMainType.Baggage:
                        iconClass = faSuitcase;
                        break;
                    case IncludedServiceMainType.Meal:
                        iconClass = faUtensils;
                        break;
                    case IncludedServiceMainType.InFlightEntertainment:
                        iconClass = faTv;
                        break;
                    case IncludedServiceMainType.Miles:
                        iconClass = faMedium;
                        break;
                    case IncludedServiceMainType.Transfer:
                        iconClass = faRandom;
                        break;
                    case IncludedServiceMainType.Seat:
                        iconClass = faCouch;
                        break;
                    case IncludedServiceMainType.Other:
                        iconClass = faCircle;
                        break;
                }
        }
        return iconClass;
    }
};

export default FareHeaderIncludedServices

interface FreeBaggeIndicatorProps {
    HasFreeBaggage: boolean;
}

const FreeBaggeIndicator: React.FC<FreeBaggeIndicatorProps> = ({ HasFreeBaggage }) => {
    const title = HasFreeBaggage ? "This fare has free baggage allowance for at least one connection" : "This fare has no free baggage allowance."
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const elemRef = useRef<HTMLSpanElement>(null);

    return (
        <React.Fragment>
            <Popover target={elemRef} isOpen={open} toggle={toggle}>
                <PopoverBody>
                    {title}
                </PopoverBody>
            </Popover>
            <span
                className="fa-layers fa-fw "
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onTouchStart={(e) => { e.stopPropagation(); setOpen(true) }}
                onTouchEnd={() => setOpen(false)}
                ref={elemRef} >
                <FontAwesomeIcon style={{ opacity: HasFreeBaggage ? 1 : 0.5 }} title={title} icon={faLuggageCart} />
                <FontAwesomeIcon
                    style={{ "--fa-secondary-opacity": "0.7", "--fa-primary-color": "black", "color": `${HasFreeBaggage ? "green" : "red"}` } as CSSProperties}
                    icon={HasFreeBaggage ? faCheckCircle : faExclamationTriangle}
                    // color={HasFreeBaggage ? "green" : "red"}
                    transform="shrink-7 down-6 right-5" />
            </span>
        </React.Fragment>

    );



};

